<!-- eslint-disable vue/no-unused-vars -->
<template>
  <div>
    <b-card title="Ações">
      <b-row class="my-1 text-right mt-2 d-flex align-items-center">
        <b-col sm="12" md="6" class="d-flex">
          <b-form-group class="flex-grow-1">
            <b-form-input
              type="text"
              v-model="search"
              placeholder="Buscar item"
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col sm="12" md="6" class="d-flex justify-content-end">
          <b-button
            class="mb-1"
            variant="danger"
            @click="$router.push({ name: 'nova-despesa' })"
            block
          >
            Nova Despesa +
          </b-button>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <b-form-group class="mb-2">
            <label for="category" class="font-weight-bold text-primary"
              >Projetos :</label
            ><br />
            <b-form-select
              v-if="projects.length > 0"
              id="projectSelect"
              v-model="selectedProjectId"
              :options="projects"
              value-field="id"
              text-field="name"
              placeholder="Selecione um projeto"
              @change="handleFilterChange"
            ></b-form-select>
            <label>
              <div
                @click="handleShowArquived()"
                :style="{ color: 'blue', padding: '5px 0', cursor: 'pointer' }"
              >
                {{
                  showArquivded ? 'Ocultar arquivados' : 'Mostrar arquivados'
                }}
              </div></label
            >
          </b-form-group>
        </b-col>

        <b-col>
          <b-form-group>
            <label for="category" class="font-weight-bold text-primary"
              >Status :</label
            >
            <b-form-select
              v-if="projects.length > 0"
              id="projectSelect"
              v-model="statusOptionSelected"
              :options="statusOptions"
              value-field="value"
              text-field="name"
              placeholder="Selecione um status"
              @change="handleFilterChange"
            ></b-form-select>
          </b-form-group>
        </b-col>

        <b-col>
          <b-form-group>
            <label for="category" class="font-weight-bold text-primary"
              >Categoria :</label
            >
            <b-form-select
              v-if="projects.length > 0"
              id="projectSelect"
              v-model="categoryOptionSelected"
              :options="categoryOptions"
              value-field="value"
              text-field="name"
              placeholder="Selecione uma categoria"
              @change="handleFilterChange"
            ></b-form-select>
          </b-form-group>
        </b-col>
      </b-row>
    </b-card>
    <b-card title="Despesas de projetos / escritório">
      <!-- Tabela principal  -->
      <b-table
        responsive="sm"
        small
        :items="filter()"
        :fields="fields"
        show-empty
        class="p-1 w-100 table-details"
        :per-page="1000"
        :current-page="0"
      >
        <template #empty>
          <b-alert show variant="primary">
            <p class="text-center">
              <br />
              <strong>Não há dados para exibir</strong>
            </p>
            <hr />
          </b-alert>
        </template>
        <template v-slot:cell(transaction_date)="{ item }">
          {{ dateFormatter(item.transaction_date) }}
        </template>
        <template v-slot:cell(reference_date)="{ item }">
          {{ dateReferenceMonthFormatter(item.reference_date) }}
        </template>
        <template v-slot:cell(value)="{ item }">
          {{ `R$ ${numberToMonetary(item.value)}` }}
        </template>
        <template v-slot:cell(project_name)="{ item }">
          <small> {{ item.project_code }} </small>- {{ item.project_name }}
        </template>
        <template v-slot:cell(supplier.supplier_name)="{ item }">
          {{
            item.supplier && item.supplier.supplier_name
              ? item.supplier.supplier_name
              : 'Não informado'
          }}
        </template>
        <template v-slot:cell(actions)="{ item }">
          <Actions
            @openModal="openEditModal(item)"
            @openModaDelete="openDeleteModal(item)"
            :item="item"
          />
        </template>
      </b-table>
      <b-pagination
        class="justify-content-center"
        v-model="currentPage"
        :total-rows="totalRows"
        :per-page="perPage"
        @change="getPage"
        aria-controls="my-table"
      ></b-pagination>
      <div class="mr-2 d-flex justify-content-end">
        <strong>Total de Despesas: {{ totalRows }} </strong>
      </div>

      <!-- Modal -->
      <Modal
        :modal="modal"
        :modalForm="modalForm"
        :editable="editable"
        :itemUpdate="itemUpdate"
        @updateList="handleUpdateList"
        @renderList="getAll"
        @closeModal="closeModal()"
      />
    </b-card>
    <b-card title="Fluxo financeiro">
      <!-- Tabela dos totais -->
      <b-table
        responsive="sm"
        small
        :items="totals"
        :fields="fieldsTotal"
        show-empty
        class="p-1 w-100 table-details"
      >
        <template #empty>
          <b-alert show variant="primary">
            <p class="text-center">
              <br />
              <strong>Não há dados para exibir</strong>
            </p>
            <hr />
          </b-alert>
        </template>
        <template v-slot:cell(totalRealizado)="{ item }">
          {{ `R$ ${numberToMonetary(item.totalRealizado)}` }}
        </template>
        <template v-slot:cell(totalPrevisto)="{ item }">
          {{ `R$ ${numberToMonetary(item.totalPrevisto)}` }}
        </template>
        <template v-slot:cell(totalGeral)="{ item }">
          {{ `R$ ${numberToMonetary(item.totalGeral)}` }}
        </template>
      </b-table>
    </b-card>
    <!-- Grafico -->
    <b-card v-if="items && items.length > 0" title="Evolução">
      <div
        class="d-flex justify-content-center align-items-center overflow-scroll-mobile"
      >
        <Chart :chart-options="customOptions" :chart-series="customSeries" />
      </div>
    </b-card>

    <b-card
      v-if="costPerSupplier_data.render"
      title="Pagamentos por fornecedor"
    >
      <b-col class="text-center mx-auto w-10 h-50">
        <ProfitabilityChart :chartData="costPerSupplier_data" />
      </b-col>
    </b-card>

    <b-card
      v-if="costPerSupplier_data.render"
      title="Pagamentos por fornecedor"
    >
      <b-col class="text-center mx-auto w-10 h-50">
        <ProfitabilityChart :chartData="costPerSupplierTreeMap_data" />
      </b-col>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BButton,
  BTable,
  BFormInput,
  BFormGroup,
  BCol,
  BFormSelect,
  BAlert,
  BPagination,
} from 'bootstrap-vue';
import { VBTooltip } from 'bootstrap-vue';
import Actions from './components/ActionsExpense.vue';
import Modal from './components/ModalRevenue.vue';
import Chart from './components/Chart.vue';
import ProfitabilityChart from '@/components/Chart.vue';

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BCard,
    BTable,
    BRow,
    BButton,
    Modal,
    Actions,
    BFormInput,
    BFormGroup,
    Chart,
    BCol,
    BFormSelect,
    BAlert,
    BPagination,
    ProfitabilityChart,
  },
  data: () => ({
    modal: false,
    showArquivded: false,
    modalForm: false,
    editable: false,
    itemUpdate: null,
    selectedProjectId: null,
    search: '',
    perPage: 2000,
    currentPage: 1,
    totalRows: 0,
    filterTrigged: false,
    projects: [],
    costPerSupplier_data: { render: false },
    costPerSupplierTreeMap_data: { render: false },

    statusOptions: [
      {
        value: 'todos',
        name: 'Todos',
      },
      {
        value: 'previsto',
        name: 'Previsto',
      },
      {
        value: 'realizado',
        name: 'Realizado',
      },
    ],
    categoryOptions: [
      {
        value: 'todos',
        name: 'Todos',
      },
      {
        value: false,
        name: 'Escritorio',
      },
      {
        value: true,
        name: 'Projeto',
      },
    ],
    statusOptionSelected: 'todos',
    categoryOptionSelected: 'todos',

    fields: [
      {
        key: 'actions',
        label: 'Ações',
        sortable: false,
      },
      {
        key: 'transaction_date',
        label: 'Data',
        sortable: true,
      },
      {
        key: 'value',
        label: 'Valor',
        sortable: false,
      },
      {
        key: 'project_name',
        label: 'Cod - Projeto',
        sortable: false,
      },

      {
        key: 'reference_date',
        label: 'Referência',
        sortable: true,
      },
      {
        key: 'supplier.supplier_name',
        label: 'Fornecedor',
        sortable: true,
      },
      {
        key: 'status',
        label: 'Status',
        sortable: true,
      },
      {
        key: 'type',
        label: 'Condição de pagamento',
        sortable: true,
      },
    ],

    fieldsTotal: [
      {
        key: 'totalPrevisto',
        label: 'Vou pagar',
        sortable: false,
      },
      {
        key: 'totalRealizado',
        label: 'Já paguei',
        sortable: false,
      },
      {
        key: 'totalGeral',
        label: 'Total',
        sortable: false,
      },
    ],
    items: [],
    totals: [],
    costsCategoryList: false,
    customOptions: {
      chart: {
        height: 350,
        type: 'line',
      },
      stroke: {
        curve: 'smooth',
      },
      xaxis: {
        categories: [],
      },
    },
    customSeries: [
      {
        name: 'Despesas Pagas',
        data: [],
      },
    ],
  }),

  created() {
    this.getAll();
    this.getProjects();
  },

  computed: {
    currentWorkspace() {
      return this.$store.getters.currentWorkspace;
    },
  },

  methods: {
    async getPage(val) {
      this.currentPage = val;
      if (this.filterTrigged) {
        await this.getFilter();
      } else {
        await this.getAll();
      }
    },
    async processChartData(revenues) {
      const monthlyData = {};
      const chartCategories = [];

      revenues.forEach((revenue) => {
        const month = this.dateReferenceMonthFormatter(revenue.reference_date);
        if (!monthlyData[month]) {
          monthlyData[month] = 0;
          chartCategories.push(month);
        }
        monthlyData[month] += revenue.value;
      });

      this.customOptions.xaxis.categories = chartCategories;
      this.customSeries[0].data = Object.values(monthlyData);
      this.customOptions.dataLabels = {
        formatter: (val) => `R$ ${this.numberToMonetary(val)}`,
      };
    },

    async getAll() {
      this.items = [];
      let body = {
        workspace_id: this.currentWorkspace.id,
        archived: this.showArquivded,
        page: this.currentPage,
        perPage: this.perPage,
      };
      this.$store
        .dispatch('getAllExpenses', body)
        .then((resp) => {
          if (resp) {
            let arrayTranform = [];
            arrayTranform.push(resp.totals);
            this.items = resp.data;

            const supplierCost = this.items.reduce((acc, item) => {
              if (item.supplier) {
                const { id, supplier_name } = item.supplier;
                const existingSupplier = acc.find((s) => s.supplier_id === id);

                if (existingSupplier) {
                  existingSupplier.value += item.value;
                } else {
                  acc.push({
                    supplier_id: id,
                    supplier_name,
                    value: item.value,
                  });
                }
              }
              return acc;
            }, []);
            const sortedData = resp.data.sort(
              (a, b) =>
                new Date(a.transaction_date) - new Date(b.transaction_date)
            );

            this.totals = arrayTranform;
            this.processChartData(sortedData);
            this.setcostPerSupplier_data(supplierCost);
            this.setcostPerSupplierTreeMap_data(supplierCost);

            this.totalRows = resp.pagination.total;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async getFilter() {
      this.items = [];
      this.totals = [];
      let body = {
        workspace_id: this.$store.getters.currentWorkspace.id,
        archived: this.showArquivded,
        project_id: this.selectedProjectId,
        status: this.statusOptionSelected,
        category: this.categoryOptionSelected,
        page: this.currentPage,
        perPage: this.perPage,
      };

      this.$store
        .dispatch('getExpenses', body)
        .then((resp) => {
          if (resp) {
            let arrayTranform = [];
            arrayTranform.push(resp.totals);
            this.items = resp.data;
            this.totals = arrayTranform;

            const supplierCost = this.items.reduce((acc, item) => {
              if (item.supplier) {
                const { id, supplier_name } = item.supplier;
                const existingSupplier = acc.find((s) => s.supplier_id === id);

                if (existingSupplier) {
                  existingSupplier.value += item.value;
                } else {
                  acc.push({
                    supplier_id: id,
                    supplier_name,
                    value: item.value,
                  });
                }
              }
              return acc;
            }, []);

            this.processChartData(resp.data);
            this.setcostPerSupplier_data(supplierCost);
            this.setcostPerSupplierTreeMap_data(supplierCost);
            this.totalRows = resp.pagination.total;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async getProjects() {
      this.$store
        .dispatch('_getProjects', {
          workspace_id: this.$store.getters.currentWorkspace.id,
        })
        .then((resp) => {
          this.projects = [];
          this.projects.push({
            id: null,
            name: 'Todos',
          });
          resp.data.map((e) => {
            if (this.showArquivded) {
              this.projects.push({
                id: e.id,
                name: `${e.project_code} - ${e.project_name} ${
                  e.is_archived ? '(Arquivado)' : ''
                }`,
              });
            } else {
              if (e.is_archived) {
                return null;
              } else {
                this.projects.push({
                  id: e.id,
                  name: `${e.project_code} - ${e.project_name} ${
                    e.is_archived ? '(Arquivado)' : ''
                  }`,
                });
              }
            }
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async setcostPerSupplier_data(props) {
      this.costPerSupplier_data = {};
      const data = [];
      const date = [];
      const project_ids = [];

      props.map((e) => {
        data.push(e.value / 100);
        date.push(e.supplier_name);
        project_ids.push(e.supplier_id);
      });

      this.costPerSupplier_data = {
        render: date.length > 0,
        width: '100%',
        height: 1500,
        chartOptions: {
          chart: {
            type: 'bar',

            events: {
              dataPointSelection: (event, chartContext, config) => {
                const dataIndex = config.dataPointIndex;
                const project_id = project_ids[dataIndex];

                if (project_id) {
                  this.$router.push(`/projetos/dashboard/${project_id}`);
                }
              },
            },
          },

          plotOptions: {
            bar: {
              borderRadius: 4,
              horizontal: true,
              columnWidth: '200px',
              borderRadiusApplication: 'end',
              distributed: true,
            },
          },
          dataLabels: {
            enabled: false,
          },
          yaxis: {
            categories: data,
          },
          xaxis: {
            tickAmount: 3,
            labels: {
              formatter: function (number) {
                if (isNaN(number)) {
                  return 'Invalid input';
                }
                if (number === 0) {
                  return 'R$ 0,00';
                }
                if (number < 100) {
                  const formattedCents = number.toFixed(2).padStart(5, '0');
                  return `0,${formattedCents}`;
                }
                const [integerPart, decimalPart] = number
                  .toFixed(2)
                  .toString()
                  .split('.');
                const formattedIntegerPart = integerPart.replace(
                  /\B(?=(\d{3})+(?!\d))/g,
                  ','
                );
                const result = `R$ ${formattedIntegerPart},${decimalPart}`;
                return result;
              },
            },
            categories: date,
          },
          tooltip: {
            x: { show: true },
            y: {
              formatter: function (number) {
                if (isNaN(number)) {
                  return 'Invalid input';
                }
                if (number === 0) {
                  return 'R$ 0,00';
                }
                if (number < 100) {
                  const formattedCents = number.toFixed(2).padStart(5, '0');
                  return `0,${formattedCents}`;
                }
                const [integerPart, decimalPart] = number
                  .toFixed(2)
                  .toString()
                  .split('.');
                const formattedIntegerPart = integerPart.replace(
                  /\B(?=(\d{3})+(?!\d))/g,
                  ','
                );
                const result = `R$ ${formattedIntegerPart},${decimalPart}`;
                return result;
              },
            },
          },
        },
        chartSeries: [
          {
            name: 'Custo',
            data,
          },
        ],
      };
    },

    async setcostPerSupplierTreeMap_data(props) {
      this.costPerSupplierTreeMap_data = {};

      const chartData = props.map((e) => ({
        x: e.supplier_name,
        y: parseFloat(e.value.toFixed(2)),
        project_id: e.supplier_id,
      }));

      this.costPerSupplierTreeMap_data = {
        render: chartData.length > 0,
        width: '100%',
        height: 500,
        chartOptions: {
          chart: {
            type: 'treemap',
            events: {
              dataPointSelection: (event, chartContext, config) => {
                const dataIndex = config.dataPointIndex;
                const project_id = chartData[dataIndex].project_id;

                if (project_id) {
                  this.$router.push(`/projetos/dashboard/${project_id}`);
                }
              },
            },
          },
          tooltip: {
            custom: ({ seriesIndex, dataPointIndex, w }) => {
              const value = w.config.series[seriesIndex].data[dataPointIndex].y;
              const formattedValue = `R$ ${this.numberToMonetary(value)} `;
              const label = w.config.series[seriesIndex].data[dataPointIndex].x;

              return `
            <div style="padding: 10px; border: 1px solid #ddd; background: #fff;">
              <strong>${label}</strong><br />
              <span>${formattedValue}</span>
            </div>`;
            },
          },
          plotOptions: {
            treemap: {
              enableShades: true,
              distributed: true,
              colorScale: {
                ranges: [
                  {
                    from: 0,
                    to: 1000,
                    color: '#f5a623',
                  },
                  {
                    from: 1001,
                    to: 5000,
                    color: '#7bd9a5',
                  },
                  {
                    from: 5001,
                    to: 10000,
                    color: '#008ffb',
                  },
                ],
              },
            },
          },
        },
        chartSeries: [
          {
            data: chartData,
          },
        ],
      };
    },

    openEditModal(item) {
      this.modal = true;
      this.editable = true;
      this.modalOpenDelete = false;
      this.modalForm = true;
      this.itemUpdate = item.id;
    },
    openDeleteModal(item) {
      this.modal = true;
      this.modalForm = false;
      this.modalOpenDelete = true;
      this.itemUpdate = item.id;
    },
    openModalDefault() {
      this.modal = true;
      this.modalForm = true;
      this.modalOpenDelete = false;
      this.editable = false;
      // this.itemUpdate = item.id
    },
    closeModal() {
      this.modal = false;
      this.editable = false;
    },

    filter() {
      if (this.search.trim() === '') {
        return this.items;
      } else {
        return this.items.filter((item) => {
          const searchText = this.search.toLowerCase();

          return (
            (item.project_name ?? '').toLowerCase().includes(searchText) ||
            (item.project_code ?? '').toLowerCase().includes(searchText) ||
            (item.value ?? '').toString().toLowerCase().includes(searchText) ||
            (item.supplier?.supplier_name ?? '')
              .toLowerCase()
              .includes(searchText) ||
            (item.status ?? '').toLowerCase().includes(searchText)
          );
        });
      }
    },
    cleanFilter() {
      this.search = '';
    },

    handleUpdateList() {
      this.getAll();
    },
    handleShowArquived() {
      this.showArquivded = !this.showArquivded;
      this.projects = [];
      this.getAll();
      this.getProjects();
    },
    handleFilterChange() {
      this.getFilter();
      this.filterTrigged = true;
    },
  },
};
</script>

<style>
.overflow-scroll-mobile {
  overflow-x: auto;
  white-space: nowrap;
}
.table-details {
  max-height: 70vh;
  overflow-y: auto;
}
</style>

<template>
  <div>
    <feather-icon
      v-b-tooltip.hover.righttop.v-primary
      title="Editar despesa"
      icon="EditIcon"
      size="15"
      @click="$router.push(`/lancamento-de-despesa/${item.id}`)"
      style="cursor: pointer"
    />
    <feather-icon
      @click="$router.push(`/lancamento-de-despesa/${item.id}`)"
      v-b-tooltip.hover.lefttop.v-primary
      size="15"
      title="Detalhar despesa"
      icon="EyeIcon"
      class="ml-1"
      style="cursor: pointer"
    />
  </div>
</template>

<script>
import { VBTooltip } from 'bootstrap-vue';
import Ripple from 'vue-ripple-directive';
export default {
  directives: {
    'b-tooltip': VBTooltip,
    Ripple
  },
  components: {},
  props: {
    item: { type: Object, default: () => {} }
  }
};
</script>
